export default [
{
	path: '/',
	name: 'SignIn',
	component: () => import('@/views/SignIn'),
	meta: {
		includeInNavbar: false,
		title: 'Sign In',
		guest: true
	}
}, 
{
	path: '/admin',
	name: 'Admin',
	component: () => import('@/views/Admin'),
	meta: {
		includeInNavbar: false,
		requiresAuth: true
	},
	children: [{
		path: 'manage-users',
		name: "Admin.ManageUsers",
		component: () => import('../views/Admin/ManageUsers'),
		meta: {
			title: "Manage Users"
		}
	}, 
	{
		path: 'manage-classes',
		name: "Admin.ManageClasses",
		component: () => import('../views/Admin/ManageClasses'),
		meta: {
			title: "Manage Classes"
		}
	}, {
		path: 'manage-assessments',
		name: "Admin.ManageAssessments",
		component: () => import('../views/Admin/ManageAssessments'),
		meta: {
			title: "Manage Assessments"
		}
	},  {
		path: 'manage-resources',
		name: "Admin.ManageResources",
		component: () => import('../views/Admin/ManageResources'),
		meta: {
			title: "Manage Resources"
		}
	}, 
	]
}, 
{
	path: '/officer',
	name: 'Officer',
	component: () => import('@/views/Officer'),
	meta: {
		includeInNavbar: false,
		requiresAuth: true
	},
	children: [{
		path: 'view-schools/:id?/:classId?',
		name: "Officer.ViewSchools",
		component: () => import('../views/Officer/ViewSchools'),
		meta: {
			title: "Senarai Sekolah",
			active: ['class-details']
		},
	},
	{
		path: '/class-details/:classId/:id?',
		name: 'class-details',
		component: () => import('@/components/ClassDetails.vue'),
		props: true,
	  },
	{
		path: 'view-students/:id?',
		name: "Officer.ViewStudents",
		component: () => import('../views/Officer/ViewStudents'),
		meta: {
			title: "Senarai Murid"
		}
	},
	{
		path: 'view-assessments/:id?',
		name: "Officer.ViewAssessments",
		component: () => import('../views/Officer/ViewAssessments'),
		meta: {
			title: "Senarai Penilaian"
		}
	},
	{
		path: 'assessments',
		name: "Officer.Assessments",
		component: () => import('../views/Officer/Assessment'),
		meta: {
			title: "Jawab Penilaian",
		},
	}, 
	{
		path: 'handouts',
		name: "Officer.Handouts",
		component: () => import('../views/Officer/Handouts'),
		meta: {
			title: "Bahan Belajar",
		}
	},
	]
},
{
	path: '/my-account',
	name: 'MyAccount',
	component: () => import('@/views/MyAccount'),
	meta: {
		includeInNavbar: false,
		requiresAuth: true
	},
	children: [
	{
		path: 'dependants/:id?',
		name: "MyAccount.Dependants",
		component: () => import('../views/MyAccount/Dependants'),
		meta: {
			title: "Senarai Murid",
		}
	}, 
	{
		path: 'assessments',
		name: "MyAccount.Assessments",
		component: () => import('../views/MyAccount/Assessment'),
		meta: {
			title: "Jawab Penilaian",
		},
	}, 
	{
		path: 'handouts',
		name: "MyAccount.Handouts",
		component: () => import('../views/MyAccount/Handouts'),
		meta: {
			title: "Bahan Belajar",
		}
	}]
}, {
	path: '/crt',
	name: 'CRT',
	component: () => import('@/views/CRT'),
	meta: {
		includeInNavbar: false,
		requiresAuth: true
	},
	children: [{
		path: 'home',
		name: "CRT.Home",
		component: () => import('../views/CRT/Home'),
		meta: {
			title: "Home"
		}
	}, {
		path: 'assessments',
		name: "CRT.Assessments",
		component: () => import('../views/CRT/Assessments'),
		meta: {
			title: "Assessments",
		}
	}, {
		path: 'certificates',
		name: "CRT.Certificates",
		component: () => import('../views/CRT/Certificates'),
		meta: {
			title: "Certificates",
		}
	}, {
		path: 'profile',
		name: "CRT.Profile",
		component: () => import('../views/MyAccount/Profile'),
		meta: {
			title: "My Profile",
		}
	}]
}, {
	path: '*',
	name: 'PageNotFound',
	component: () => import('@/views/PageNotFound'),
	meta: {
		includeInNavbar: false,
	}
}]