var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-light fixed-top",class:{ expand: _vm.expandNavbar, 'bg-white shadow-bottom': _vm.hasNavbarPad },attrs:{"id":"main-navbar"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"px-0 py-0 px-md-2"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('img',{staticClass:"logo-img",staticStyle:{"width":"54px"},attrs:{"src":require("@/assets/images/majujohor-logo.png")}}),_c('img',{staticClass:"logo-img",staticStyle:{"width":"88px"},attrs:{"src":require("@/assets/images/kpm-logo.png")}}),_c('img',{staticClass:"logo-img",staticStyle:{"width":"58px"},attrs:{"src":require("@/assets/logo.png")}}),_c('img',{staticClass:"logo-img",staticStyle:{"width":"88px"},attrs:{"src":require("@/assets/images/mdec-logo.png")}}),_c('img',{staticClass:"text-logo d-none d-sm-inline-block",attrs:{"src":require("@/assets/ycp_text.png")}})])],1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav me-auto mb-2 mb-lg-0"},[_vm._m(0),_vm._l((_vm.routes.filter(
            (route) => route.meta && route.meta.includeInNavbar
          )),function(route){return _c('li',{key:route.name,staticClass:"nav-item lh-lg"},[_c('router-link',{staticClass:"nav-link",class:{
              active: _vm.$route.name == route.name,
              'text-light': !_vm.hasNavbarPad,
              'text-dark': _vm.hasNavbarPad,
            },attrs:{"to":{ name: route.name }},nativeOn:{"click":function($event){_vm.expandNavbar = false}}},[_vm._v(" "+_vm._s((route.meta && route.meta.title) || route.name)+" ")])],1)})],2)]),_c('navbar-user',{nativeOn:{"click":function($event){_vm.expandNavbar = false}}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"d-lg-none display-6"},[_c('span',{staticClass:"badge bg-secondary w-100 py-3 mb-4"},[_vm._v("MENU")])])
}]

export { render, staticRenderFns }