<template>
  <nav
    id="main-navbar"
    class="navbar navbar-expand-lg navbar-light fixed-top"
    :class="{ expand: expandNavbar, 'bg-white shadow-bottom': hasNavbarPad }"
  >
    <div class="container-fluid">
      <div class="px-0 py-0 px-md-2">
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/images/majujohor-logo.png" class="logo-img" style="width: 54px;" />
          <img src="@/assets/images/kpm-logo.png" class="logo-img" style="width: 88px;" />
          <img src="@/assets/logo.png" class="logo-img" style="width: 58px;" />
          <img src="@/assets/images/mdec-logo.png" class="logo-img" style="width: 88px;" />
          <img src="@/assets/ycp_text.png" class="text-logo d-none d-sm-inline-block"/>
        </router-link>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="d-lg-none display-6">
            <span class="badge bg-secondary w-100 py-3 mb-4">MENU</span>
          </li>
          <li
            class="nav-item lh-lg"
            v-for="route in routes.filter(
              (route) => route.meta && route.meta.includeInNavbar
            )"
            :key="route.name"
          >
            <router-link
              class="nav-link"
              :to="{ name: route.name }"
              :class="{
                active: $route.name == route.name,
                'text-light': !hasNavbarPad,
                'text-dark': hasNavbarPad,
              }"
              @click.native="expandNavbar = false"
            >
              {{ (route.meta && route.meta.title) || route.name }}
            </router-link>
          </li>
        </ul>
      </div>
      <navbar-user @click.native="expandNavbar = false" />
    </div>
  </nav>
</template>

<script>
import Routes from "@/router/routes";
import NavbarUser from "@/components/NavbarUser";

export default {
  components: {
    NavbarUser,
  },
  data() {
    return {
      routes: Routes,
      expandNavbar: false,
      hasNavbarPad: false,
    };
  },
  watch: {
    $route: {
      handler() {
        this.expandNavbar = false;

        this.$nextTick(() => {
          let navbarPad = document.getElementsByClassName("navbar-pad");

          this.hasNavbarPad = navbarPad.length > 0;
        });
      },
      immediate: true,
    },
  },
};
</script>