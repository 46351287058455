<template>
  <div v-if="!isLoading && !user">
    <div class="dropdown d-inline">
      <ul
        class="dropdown-menu dropdown-menu-end py-0"
        aria-labelledby="dropdownSignUp"
      >
      </ul>
    </div>
  </div>
  <div v-else-if="!isLoading && user" class="dropdown nav-user-dropdown">
    <template v-if="user.role && user.role.name == 'Admin'">
      <button
        class="btn btn-secondary no-pseudo dropdown-toggle"
        type="button"
        id="dropdownUserMenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ Helper.userInitial(user) }}
      </button>
      <ul
        class="dropdown-menu dropdown-menu-end"
        aria-labelledby="dropdownUserMenu"
      >
        <li>
          <p class="dropdown-header mb-0 fs-6 text-truncate">
            <small>{{ user.name }}</small>
          </p>
        </li>
        <li class="dropdown-divider"></li>
        <li>
          <router-link class="dropdown-item" :to="{ name: 'Admin' }">
            <i class="fas fa-user-shield fa-fw me-2" />
            Admin Section
          </router-link>
        </li>
        <li>
          <button class="dropdown-item" @click="Helper.doSignOut()">
            <i class="fas fa-sign-out-alt fa-fw me-2" />
            Sign Out
          </button>
        </li>
      </ul>
    </template>
    <template v-if="user.role && user.role.name == 'Officer'">
      <button
        class="btn btn-secondary no-pseudo dropdown-toggle"
        type="button"
        id="dropdownUserMenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ Helper.userInitial(user) }}
      </button>
      <ul
        class="dropdown-menu dropdown-menu-end"
        aria-labelledby="dropdownUserMenu"
      >
        <li>
          <router-link class="dropdown-item" :to="{ name: 'Officer' }">
            <i class="fas fa-user-shield fa-fw me-2" />
            Seksyen Pegawai
          </router-link>
        </li>
        <li>
          <button class="dropdown-item" @click="Helper.doSignOut()">
            <i class="fas fa-sign-out-alt fa-fw me-2" />
            Sign Out
          </button>
        </li>
      </ul>
    </template>
    <template v-if="user.role && user.role.name == 'Authenticated'">
      <span
        :to="{ name: user.isCRT ? 'CRT' : 'MyAccount' }"
        class="overflow-hidden d-none d-sm-inline-flex me-2" style="font-weight: 500; color: black;"
        :class="
          $route.name && $route.name.includes(user.isCRT ? 'CRT' : 'MyAccount')
            ? 'btn-secondary'
            : 'btn-light'
        "
      >
        {{ user.name }}
      </span>
      <img src="@/assets/images/unknown.jpg" class="border rounded-circle ms-2" style="height: 30px;">
    </template>
  </div>
  <button class="btn btn-sm btn-light rounded-pill" v-else-if="isLoading">
    <span class="spinner-border spinner-border-sm"></span>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      user: null,
      noReturnPath: ["SignIn", "SignUp", "SignUp-CRT", "Home", "OurTutors"],
    };
  },
  methods: {
    async getUser() {
      if (!this.user) {
        this.isLoading = true;
      }

      try {
        const { data } = await this.API.get("users/me");

        if (data) {
          this.user = data;

          this.$store.commit("saveUser", this.user);
        }
      } catch (err) {
      } finally {
        this.isLoading = false;
      }
    },
    validateToken() {
      if (this.Helper.validateToken()) {
        this.getUser();
      } else {
        this.user = null;

        this.$store.commit("saveUser", null);
      }
    },
  },
  mounted() {
    this.validateToken();

    window.addEventListener("token-changed", (data) => {
      if (data.detail && data.detail.result) {
        if (!this.isLoading) {
          this.getUser();
        }
      } else {
        this.user = null;

        this.$store.commit("saveUser", null);
      }
    });
  },
};
</script>